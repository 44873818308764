// put your brand color here 

$titleColor: #090B0E;
$title2Color: #3D4C5E;
$mainColor : #1C64F2;
$graDianColor : linear-gradient(180deg, #5F36AF 0%, #2C96FB 100%);
$bodyColor: #3d4c5e;
$hover: rgba(4, 50, 103, 0.603);
$tbg: #E4F9ED;
$green: #00CA2A;
$gold: #F3BA0E;