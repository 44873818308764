@media (min-width: 1200px) and (max-width: 1399px) {
    .topHeroWrapper{
        border-radius: 0px 0px 60px 60px;
    }
    #heroArea .rightImage .img2 {
        left: inherit !important;
        transform: inherit !important;
        img{
            width: 100%;
        }
    }
    #heroArea .leftContent .toph1 {
        font-size: 49px;
    }
    #models .bottomInfo {
        margin-top: 20px;
    }
    #conVerBox .rightContent .topInfo h3 {
        font-size: 34px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .topHeroWrapper{
        border-radius: 0px 0px 60px 60px;
    }
    #heroArea .rightImage .img2 {
        left: inherit !important;
        transform: inherit !important;
        img{
            width: 100%;
        }
    }
    #heroArea .leftContent .toph1 {
        font-size: 49px;
    }
    #models .bottomInfo {
        margin-top: 20px;
    }
    #conVerBox .rightContent .topInfo h3 {
        font-size: 34px;
    }
    #heroArea .leftContent ul li {
        margin-right: 0px;
    }
    #keyObjective .itemWrapper .singleItem {
        padding: 30px 20px;
    }
    #feature1 .rightImage {
        img{
            width: 100%;
        }
    }
    #feature1 .leftInfo .top h3 {
        font-size: 34px;
    }
    #models .leftInfo .topBox h3 {
        font-size: 34px;
    }
    #customerJourney .bottomWrapper {
        width: 90%;
    }
    #footer .footerLogo{
        margin-bottom: 20px;
    }
    #footer .footerComon {
        margin-bottom: 20px;
    }
}
@media (max-width: 1199px) {
    #contactWrapperArea .contactForm {
        padding-left: 0px;
    }
    #contactWrapperArea {
        padding: 50px 0px 50px 0px;
    }
    #pricingPlanWrapper .singlePriceItem{
        height: 100%;
    }
    #pricingPlanWrapper .singlePriceItem.business {
        min-height: 664px !important;
    }
    #pricingPlanWrapper .singlePriceItem .featureList {
        padding: 0px 14px;
    }
    #pricingPlanWrapper .singlePriceItem .topInfo h4 {
        font-size: 18px;
    }
    #pricingPlanWrapper .singlePriceItem .topInfo p {
        font-size: 13px;
    }
}
@media (max-width: 991px) {
    .topHeroWrapper {
        border-radius: 0px;
    }
    #headerArea{
        display: none;
    }
    .mobileHeader{
        display: block;
    }
    #heroArea .leftContent {
        text-align: center;
    }
    #heroArea .rightImage {
        margin-top: 30px;
        
        .mainImage{
            width: 50%;
        }
        .img2{
            width: 80%;
            left: 50%;
            img{
                width: 100%;
            }
        }
    }
    #feature1{
        .container{
            .row{
                flex-direction: column-reverse;
            }
        }
    }
    #feature1 .rightImage {
        img{
            width: 70%;
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }
    #models .bottomInfo{
        margin-top: 20px;
    }
    #models .bottomInfo .row {
        row-gap: 15px;
    }
    #conVerBox .rightContent .topInfo{
        text-align: center;
    }
    #utilizeTecnlogy .leftContent .topInfo {
        text-align: center;
    }
    #customerJourney .bottomWrapper {
        width: 100%;
    }
    #customerJourney .bottomWrapper .singleItem {
        padding: 20px;
    }
    #realtimeReporting {
        padding: 50px 0px 50px 0px;
        background-color: #f8f5fe4b;
    }
    #realtimeReporting .leftContent .topInfo {
        text-align: center;
        h3{
            font-weight: 700;
            font-size: 40px;
        }
        p{
            width: 100%;
        }
    }
    #caseStudy .mainContent p {
        width: 90%;
    }
    #footer .footerLogo{
        margin-bottom: 20px;
    }
    #footer .footerComon {
        margin-bottom: 20px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    #feature1 .leftInfo .top h3 {
        font-size: 40px;
    }
    #models .leftInfo .topBox h3 {
        font-size: 40px;
    }
    #heroArea .leftContent p {
        width: 100%;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    #heroArea .leftContent .toph1 {
        font-size: 40px;
        span{
            display: block;
        }
    }
    #heroArea .leftContent h2 {
        font-size: 24px;
        line-height: 33px;
    }
    #heroArea .leftContent p {
        width: 100%;
    }
    #heroArea .rightImage .mainImage {
        width: 74%;
    }
    #heroArea .rightImage .img2 {
        width: 100%;
    }
    #keyObjective {
        padding: 20px 0px 50px 0px;
    }
    #feature1 .leftInfo {
        text-align: center;
        .top{
            h3{
                font-size: 34px;

            }
        }
    }
    #feature1 .leftInfo .itemWrapper .singleItem {
        width: 100%;
    }
    #feature1 .leftInfo .itemWrapper .singleItem:nth-child(2) {
        margin-left: 0px;
    }
    #models .leftInfo{
        text-align: center;
        .topBox{
            h3{
                font-size: 34px;
            }
            p{
                width: 100%;
            }
        }
    } 
    #feature1 {
        padding: 30px 0px 20px 0px;
    }
    #models .leftInfo .bottomPart .topInfo p {
        width: 100%;
    }
    #models {
        padding: 0px 0px 100px 0px;
    }
    #conVerBox .rightContent .topInfo h3 {
        font-size: 28px;
    }
    #utilizeTecnlogy {
        padding: 50px 0px 0px 0px;
        .leftContent{
            .topInfo{
                h3{
                    font-size: 34px;
                }
            }
        }
    }
    #utilizeTecnlogy .leftContent .bottomContent .singleItem .content p {
        width: 100%;
    }
    #utilizeTecnlogy .rightContent img {
        width: 80%;
        margin: 0 auto;
        display: table;
    }
    #utilizeTecnlogy .bottomInfoBox .content .awardIcon {
        height: 100%;
        left: -13px;
        top: -5px;
    }
    #utilizeTecnlogy .bottomInfoBox .content {
        padding-left: 100px;
    }
    #utilizeTecnlogy .bottomInfoBox .content h5 {
        font-size: 14px;
    }
    #utilizeTecnlogy .bottomInfoBox {
        margin-top: 10px;
    }
    #customerJourney {
        padding: 40px 0px 30px 0px;
    }
    #realtimeReporting {
        padding: 30px 0px 30px 0px;
    }
    #realtimeReporting .leftContent .topInfo h3 {
        font-size: 33px;
    }
    #footer .coptrightContent {
        text-align: center;
    }
    #footer .coptrightContent .rightLink ul {
        text-align: center;
        margin-top: 5px;
    }
}
@media (max-width: 768px) {
    #pricingPlanWrapper{
        .container{
            .row{
                row-gap: 10px;
            }
        }
    }
    
    #pricingPlanWrapper .singlePriceItem.business {
        min-height: 550px !important;
        margin-top: 0px;
        border: 1px solid #e5e5e5;
    }
    #pricingPlanWrapper .singlePriceItem{
        border: 1px solid #e5e5e5 !important;
        border-radius: 10px;
    }
}
@media (max-width: 575px) {
    #heroArea .leftContent .toph1 {
        font-size: 40px;
        span{
            display: block;
        }
    }
    #heroArea .leftContent h2 {
        font-size: 24px;
        line-height: 33px;
    }
    #heroArea .leftContent p {
        width: 100%;
    }
    #heroArea .rightImage .mainImage {
        width: 74%;
    }
    #heroArea .rightImage .img2 {
        width: 100%;
    }
    #keyObjective {
        padding: 20px 0px 50px 0px;
    }
    #feature1 .leftInfo {
        text-align: center;
        .top{
            h3{
                font-size: 34px;

            }
        }
    }
    #feature1 .leftInfo .itemWrapper .singleItem {
        width: 100%;
    }
    #feature1 .leftInfo .itemWrapper .singleItem:nth-child(2) {
        margin-left: 0px;
    }
    #models .leftInfo{
        text-align: center;
        .topBox{
            h3{
                font-size: 34px;
            }
            p{
                width: 100%;
            }
        }
    } 
    #feature1 {
        padding: 30px 0px 20px 0px;
    }
    #models .leftInfo .bottomPart .topInfo p {
        width: 100%;
    }
    #models {
        padding: 0px 0px 100px 0px;
    }
    #conVerBox .rightContent .topInfo h3 {
        font-size: 28px;
    }
    #utilizeTecnlogy {
        padding: 50px 0px 0px 0px;
        .leftContent{
            .topInfo{
                h3{
                    font-size: 34px;
                }
            }
        }
    }
    #utilizeTecnlogy .leftContent .bottomContent .singleItem .content p {
        width: 100%;
    }
    #utilizeTecnlogy .rightContent img {
        width: 80%;
        margin: 0 auto;
        display: table;
    }
    #utilizeTecnlogy .bottomInfoBox .content .awardIcon {
        height: 100%;
        left: -13px;
        top: -5px;
    }
    #utilizeTecnlogy .bottomInfoBox .content {
        padding-left: 100px;
    }
    #utilizeTecnlogy .bottomInfoBox .content h5 {
        font-size: 14px;
    }
    #utilizeTecnlogy .bottomInfoBox {
        margin-top: 10px;
    }
    #customerJourney {
        padding: 40px 0px 30px 0px;
    }
    #realtimeReporting {
        padding: 30px 0px 30px 0px;
    }
    #realtimeReporting .leftContent .topInfo h3 {
        font-size: 33px;
    }
    #footer .coptrightContent {
        text-align: center;
    }
    #footer .coptrightContent .rightLink ul {
        text-align: center;
        margin-top: 5px;
    }
    #clientSlider .borderBox {
        padding-bottom: 30px;
        overflow: hidden;
        flex-direction: column;
        row-gap: 20px;
    }
    #keyObjective{
        .container{
            .row{
                padding: 0px 6px;
            }
        }
    }
    #feature1 .leftInfo .top p {
        width: 100%;
    }
    #conVerBox .rightContent .topInfo h3 {
        font-size: 23px;
        line-height: 32px;
    }
    #utilizeTecnlogy .leftContent .topInfo h3 {
        font-size: 26px;
        line-height: 34px;
    }
    #utilizeTecnlogy .bottomInfoBox .content .awardIcon {
        opacity: 0.2;
    }
    #utilizeTecnlogy .bottomInfoBox .content {
        padding-left: 20px;
    }
    #customerJourney{
        .container{
            .bottomWrapper{
                .row{
                    padding: 0px 6px;
                }
            }
        }
    }
    #caseStudy {
        padding: 60px 0px;
    }
    #caseStudy .mainContent h3 {
        font-size: 26px;
        line-height: 33px;
    }
    #caseStudy .mainContent p {
        width: 100%;
    }
    #contactWrapperArea .leftInfoContent .topInfo{
        text-align: center;
        h5{
            justify-content: center;
        }
    }
    #contactWrapperArea .leftInfoContent .topInfo h3 {
        font-size: 28px;
        line-height: 36px;
    }
    #lottie-container.show {
        min-height: 250px;
    }
    #lottie-container.show svg {
        width: 100% !important;
    }
    .subpageHeroWrapper .subpageBannerContent .content h1 {
        font-size: 28px;
    }
    .pricingPlanBanner .content h4 {
        font-size: 26px;
    }
    .pricingPlanBanner .content p {
        font-size: 14px;
        margin-top: 5px;
    }
    .pricingPlanBanner .content .btn {
        width: 100%;
    }
    .pricingPlanBanner {
        padding-bottom: 150px !important;
    }
    #pricingPlanWrapper{
        .container{
            .row{
                padding: 0px 6px;
            }
        }
    }
    #pricingPlanWrapper .singlePriceItem {
        min-height: inherit !important;
    }
}