/*-- this is for webpage restae page desigm --*/
@import 'color.scss';
$mainfont: "Inter", serif;
*{
    margin: 0;
    padding: 0;
    outline: 0;
    font-family: $mainfont;
}
ul{
    margin: 0;
    padding: 0;
    font-family: $mainfont;
    li{
        margin: 0;
        padding: 0;
        list-style: none;
        font-family: $mainfont;
    }
}
a{
    text-decoration: none;
    color: $titleColor;
    transition: 0.4s;
    font-family: $mainfont;
}
a:hover{
    text-decoration: none;
    color: $titleColor;
}
h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
    font-family: $mainfont;
}
body{
    position: relative;
    font-family: $mainfont;
}
p{
    margin: 0;
    padding: 0;
    color: $bodyColor;
    font-family: $mainfont;
}
.break-3{
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.break-4{
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}