@import 'import/reset.scss';
@import 'import/_color.scss';
@import './components/_button';


/*------------------------
main code start here 
-------------------------*/
@import './page/_home';
@import './page/_contactus';
@import './page/_pricing';

// responsive
@import './_responsive';