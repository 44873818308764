.pricingPlanBanner{
    padding-top: 50px !important;
    padding-bottom: 200px !important;
    .content{
        text-align: center;
        h4{
            font-weight: 700;
            font-size: 40px;
        }
        p{
            font-weight: 500;
            font-size: 18px;
            margin-top: 10px;
        }
        .btn{
            margin-top: 20px;
        }
    }
}
#pricingPlanWrapper{
    padding-bottom: 50px;
    margin-top: -120px;
    .pl-0{
        padding-left: 0px !important;
    }
    .pr-0{
        padding-right: 0px !important;
    }
    .singlePriceItem{
        border: 1px solid #e5e5e5;
        background-color: #fff;
        min-height: 539px;
        .topInfo{
            text-align: center;
            position: relative;
            padding: 55px 20px 40px 20px;
            h3{
                position: absolute;
                width: 95%;
                top: 6px;
                left: 0;
                right: 0;
                margin: auto;
                background: $graDianColor;
                color: #fff;
                font-weight: 500;
                font-size: 13px;
                padding: 7px 0px;
                border-radius: 4px;
            }
            h4{
                font-weight: 600;
                font-size: 23px;
            }
            p{
                margin-top: 2px;
            }
            h5{
                margin-top: 8px;
                font-weight: 600;
                font-size: 33px;
                .dollar{
                    font-weight: 500;
                    font-size: 18px;
                    position: relative;
                    top: -13px;
                    left: -5px;
                }
                .month{
                    font-size: 18px;
                }
            }
        }
        .featureList{
            padding: 0px 30px;
            ul{
                li{
                    display: block;
                    font-weight: 400;
                    font-size: 15px;
                    margin-bottom: 20px;
                    span{
                        font-weight: 600;
                        color: $titleColor;
                    }
                }
            }
        }
        &.business{
            // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
            position: relative;
            border: none;
            min-height: 557px;
            margin-top: -20px;
            .topInfo{
                padding-top: 70px;
            }
        }
        &.elite{
            border-right: none;
        }
        &.core{
            border-left: none;
            border-right: none;
        }
        
    }
    .getButton{
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }
}