.subpageHeroWrapper{
    background-image: url("../img/pages-bg1.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    .subpageBannerContent{
        padding: 40px 0px 50px 0px;
        .content{
            h1{
                text-align: center;
                font-weight: 700;
                font-size: 40px;
                text-shadow: 6px 14px 14px rgba(0, 0, 0, 0.2);
            }
        }
    }
}
#contactWrapperArea{
    padding: 100px 0px 50px 0px;
    background-color: #fff;
    .leftInfoContent{
        .topInfo{
            h5{
                display: flex;
                column-gap: 10px;
                align-items: center;
                text-transform: uppercase;
                font-weight: 600;
                font-size: 17px;
                img{
                    height: 100%;
                }
            }
            h3{
                font-weight: 700;
                font-size: 38px;
                margin-top: 20px;
                line-height: 48px;
            }
        }
        .bottomInfo{
            margin-top: 30px;
            .singleItem{
                display: flex;
                align-items: center;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
                border-radius: 10px;
                margin-bottom: 20px;
                min-height: 130px;
                padding: 20px 30px;
                .icon{
                    min-width: 70px;
                }
                .content{
                    flex: 1;
                    h4{
                        font-weight: 700;
                        font-size: 20px;
                    }
                    p{
                        color: #666666cc;
                        font-weight: 500;
                        font-size: 15px;
                        width: 90%;
                        margin-top: 7px;
                    }
                }
            }
        }
    }
    .contactForm{
        padding-left: 50px;
        .formItem{
            margin-bottom: 15px;
            input,textarea{
                height: 55px;
                width: 100%;
                background-color: #f2f5f9b2;
                border: none;
                border-radius: 7px;
                padding: 0px 20px;
                font-weight: 600;
                font-size: 15px;
            }
            textarea{
                padding-top: 20px;
                padding-bottom: 20px;
                min-height: 340px;
                resize: none;
            }
        }
    }
}
.mapBox{
    padding-bottom: 100px;
    iframe{
        width: 100%;
        height: 400px;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    }
}
#lottie-container.show{
    min-height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
        width: 60% !important;
    }
}