.btn{
    height: 48px;
    line-height: 48px;
    padding: 0px 20px;
    font-weight: 600;
    font-size: 15px;
    i,svg{
        margin-left: 10px;
        transition: all 0.3s;
        position: relative;
        left: 0px;
    }
    &:hover{
        i,svg{
            left: 4px;
        }
    }
    &:focus{
        outline: none;
        box-shadow: none;
    }
}

.btn-check:active+.btn-primary:focus, .btn-check:checked+.btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
}