.topHeroWrapper{
    min-height: 100vh;
    background-image: url("../img/pages-bg1.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 0px 0px 150px 150px;
}
#headerArea{
    padding: 15px 0px;
    .navBoxInner{
        background-color: #fff;
        border-radius: 100px;
        padding: 0px 25px;
        box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.062);
        border: 1px solid $titleColor;
        .logoBox{
            img{
                height: 30px;
            }
        }
        .rightHeader{
            display: flex;
            justify-content: end;
            align-items: center;
            column-gap: 30px;
            nav{
                >ul{
                    >li{
                        display: inline-block;
                        margin-left: 5px;
                        position: relative;
                        padding: 27px 0px;
                        >a,button{
                            font-weight: 600;
                            font-size: 15px;
                            padding: 0px 10px;
                            display: block;
                            background-color: transparent;
                            border: none;
                            outline: none;
                            &:hover{
                                color: $mainColor;
                            }
                            &.active{
                                color: $mainColor;
                            }
                        }
                        ul{
                            position: absolute;
                            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                            background-color: #fff;
                            top: 110%;
                            left: 0;
                            min-width: 200px;
                            width: max-content;
                            padding: 10px 10px;
                            border-radius: 0px 0px 10px 10px;
                            z-index: -1;
                            opacity: 0;
                            visibility: hidden;
                            border-top: 2px solid $titleColor;
                            li{
                                display: block;
                                a{
                                    font-weight: 600;
                                    font-size: 15px;
                                    padding: 7px 10px;
                                    display: block;
                                    background-color: transparent;
                                    border: none;
                                    outline: none;
                                    &:hover{
                                        color: $mainColor;
                                    }
                                }
                            }
                        }
                        &:hover{
                            ul{
                                transition: 0.4s;
                                top: 100%;
                                opacity: 1;
                                z-index: 11;
                                visibility: visible;
                            }
                        }
                    }
                }
            }
            .rightLinkBox{
                .btn{
                    height: 42px;
                    line-height: 42px;
                    font-size: 14px;
                    svg{
                        margin-left: 3px;
                        top: -2px;
                    }
                }
            }
        }
        
    }
}
.mobileHeader{
    display: none;
    padding: 15px 0px;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 30px;
    .mobileLogo{
        img{
            max-width: 130px;
        }
    }
    .mobileRightContent{
        display: flex;
        align-items: center;
        justify-content: end;
        column-gap: 15px;
        .startButton{
            .btn{
                height: 35px;
                line-height: 32px;
                font-size: 14px;
                font-weight: 500;
            }
        }
        .menuBarButton{
            button{
                height: 30px;
                width: 30px;
                border: none;
                font-size: 20px;
                outline: none;
                box-shadow: none;
                background-color: transparent;
            }
        }
    }
}
.mobileMenu{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: #ffffff;
    .mobileHeaderMenu{
        padding: 17px 0px;
        border-bottom: 1px solid #e5e5e5;
        .mobileLogo{
            img{
                max-width: 130px;
            }
        }
        .mobileRightContent{
            display: flex;
            align-items: center;
            justify-content: end;
            column-gap: 15px;
            .menuBarButton{
                button{
                    height: 30px;
                    width: 30px;
                    border: none;
                    font-size: 20px;
                    outline: none;
                    box-shadow: none;
                    background-color: transparent;
                }
            }
        }
    }
    .bottomCotent{
        height: calc(100% - 70px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .menuLinkMain{
            flex: 1;
            padding: 10px 20px 10px 20px;
            overflow-y: auto;
            nav{
                ul{
                    li{
                        display: block;
                        a{
                            display: flex;
                            justify-content: space-between;
                            font-weight: 500;
                            font-size: 14px;
                            padding: 10px 0px;
                            .icon{
                                font-size: 13px;
                                color: #918e8e;
                            }
                        }
                    }
                }
            }
        }
        .bottomMenu{
            border-top: 1px solid #e5e5e5;
            display: flex;
            justify-content: center;
            padding: 10px 0px;
            column-gap: 15px;
            .btn{
                height: 40px;
                line-height: 37px;
                min-width: 100px;
            }
        }
    }
    
}
.mobileMenu.show{
    display: block;
}
#heroArea{
    min-height: calc(100vh - 150px);
    display: flex;
    align-items: center;
    justify-content: center;
    .leftContent{
        // .toph1{
        //     background-color: #e5e5e569;
        //     border-radius: 30px !important;
        //     width: max-content;
        //     padding: 5px 5px;
        //     margin-bottom: 20px;
        //     font-weight: 700;
        //     font-size: 13px;
        //     padding-right: 20px;
        //     /* Border */
        //     border: 1px solid transparent;
        //     border-radius: 20px;
        //     background: 
        //         linear-gradient(to right, white, white), 
        //         linear-gradient(to right, #5F36AF , #2C96FB); 
        //     background-clip: padding-box, border-box;
        //     background-origin: padding-box, border-box;
        //     i{
        //         margin-left: 5px;
        //     }
        //     span{
        //         display: inline-block;
        //         background: $graDianColor;
        //         color: #fff;
        //         padding: 6px 15px;
        //         border-radius: 30px;
        //         font-size: 11px;
        //         margin-right: 5px;
        //     }
        // }
        .toph1{
            font-weight: 800;
            font-size: 60px;
            margin-bottom: 20px;
            text-shadow: 6px 14px 14px rgba(0, 0, 0, 0.2);
            span{
                color: $mainColor;
            }
        }
        h2{
            font-weight: 700;
            font-size: 30px;
            line-height: 40px;
        }
        p{
            margin-top: 15px;
            width: 90%;
        }
        ul{
            margin-top: 20px;
            li{
                display: inline-block;
                margin-right: 10px;
            }
        }
    }
    .rightImage{
        position: relative;
        z-index: 1;
        .mainImage{
            width: 80%;
            height: 70%;
            margin: 0 auto;
            display: table;
        }
        .img2{
            position: absolute;
            bottom: 0;
            z-index: -1;
            left: 56%;
            transform: translateX(-50%);
        }
        .certificateBadge{
            position: absolute;
            bottom: 0;
            top: 0;
            left: 10%;
            margin: auto;
        }
    }
}
#clientSlider{
    padding: 40px 0px;
    background-color: #fff;
    .borderBox{
        // border: 7px solid #e5e5e5;
        border-radius: 20px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        .leftContent{
            min-width: 150px;
        }
        .rightContent{
            flex: 1;
            width: 100%;
        }
    }
    .topInfo{
        p{
            font-size: 22px;
            font-weight: 700;
            text-align: center;
            color: $titleColor;
        }
    }
    .sliderWrapper{
        position: relative;
        // border-bottom: 2px solid transparent;
        // -o-border-image: linear-gradient(90deg, rgba(139, 144, 150, 0.05) 14.72%, #D8DFE9 44%, rgba(121, 125, 131, 0.05) 85%);
        // border-image: linear-gradient(90deg, rgba(139, 144, 150, 0.05) 14.72%, #D8DFE9 44%, rgba(121, 125, 131, 0.05) 85%);
        // border-bottom: 2px solid transparent;
        // border-image-slice: 2;
        .left-shape {
            position: absolute;
            top: 0;
            left: 0px;
            width: 175.149px;
            height: 68px;
            background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 30.38%, #FFF 100%);
            z-index: 3;
        }
        .right-shape {
            position: absolute;
            top: 0;
            right: 0px;
            width: 175.149px;
            height: 68px;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 30.38%, #FFF 100%);
            z-index: 3;
        }
        .brandSlider{
            text-align: center;
            img{
                margin: 0 auto;
                display: table;
                width: 84%;
                aspect-ratio: 7/2;
                object-fit: contain;
            }
        }
    }
}
#feature1{
    padding: 60px 0px 60px 0px;
    .leftInfo{
        .top{
            h3{
                font-size: 50px;
                font-weight: 700;
                color: $mainColor;
            }
            p{
                width: 90%;
                margin-top: 10px;
            }
        }
        .itemWrapper{
            margin-top: 20px;
            .singleItem{
                margin-bottom: 20px;
                width: 80%;
                padding: 20px 30px;
                padding-left: 40px;
                border-radius: 10px;
                // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
                // &.firstItem{
                //     background-color: #ffff002e;
                // }
                // &.secondItem{
                //     background-color: #536dfe73;
                // }
                // &.thirdItem{
                //     background-color: #b2ff594a;
                // }
                p{
                    font-size: 14px;
                    color: $titleColor;
                    width: 85%;
                    font-weight: 500;
                }
                &:nth-child(2){
                    margin-left: 60px;
                }
            }
        }
    }
    .rightImage{
        display: flex;
        justify-content: space-between;
        column-gap: 30px;
        .imagebox2{
            margin-top: 250px;
        }
    }
}

#keyObjective{
    padding: 50px 0px 100px 0px;
    background-color: #f8f5fe4b;
    .topInfo{
        text-align: center;
        h3{
            font-weight: 700;
            color: $gold;
            text-shadow: 6px 14px 14px rgba(0, 0, 0, 0.2);
        }
        p{
            margin-top: 10px;
        }
    }
    .itemWrapper{
        margin-top: 40px;

        .singleItem{
            height: 100%;
            background-color: #fff;
            padding: 50px 40px;
            transition: 0.4s;
            position: relative;
            border: none !important;
            box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
            .icon{
                margin-bottom: 15px;
                img{
                    height: 40px;
                }
            }
            h3{
                font-weight: 600;
                font-size: 17px;
            }
            p{
                font-size: 15px;
                margin-top: 5px;
            }
            &.first,&.nine{
                background-color: #F5F5F5;
            }
            &.third,&.seven,&.five{
                background-color: #F5F5F5;
                border: none !important;
                box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
            }
            &.five{
                background-color: #F5F5F5;
            }
        }
    }
}
#models{
    padding: 20px 0px 100px 0px;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    .leftInfo{
        .topBox{
            h3{
                font-size: 50px;
                font-weight: 700;
                color: $mainColor;
                svg{
                    margin-left: 10px;
                }
            }
            p{
                font-size: 15px;
                margin-top: 10px;
                width: 90%;
            }
        }
        .bottomPart{
            margin-top: 30px;
            .topInfo{
                h3{
                    font-weight: 600;
                    font-size: 17px;
                    margin-bottom: 4px;
                }
                p{
                    font-size: 15px;
                    width: 80%;
                }
            }
        }
    }
    .rightInfo{
        img{
            width: 100%;
            margin: 0 auto;
            display: table;
        }
    }
    .singleItem{
        box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
        border-radius: 10px;
        padding: 20px;
        background-color: #149a9c;
        margin-bottom: 15px;
        height: 100%;
        &.item2{
            background-color: #8482FF;
        }
        h5{
            font-weight: 700;
            color: #fff;
            font-size: 16px;
        }
        p{
            color: #ffffffda;
            font-size: 14px;
            margin-top: 7px;
        }
    } 
}
#conVerBox{
    padding: 0px 0px;
    position: relative;
    z-index: 1;
    &::before{
        content: "";
        position: absolute;
        inset: 0;
        -webkit-mask-image: url('../img/customization_bg-1.png');
        mask-image: url('../img/customization_bg-1.png');
        background-color: #fafafa9c;
        -webkit-mask-size: 100% 100%;
        mask-size: 100% 100%;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        z-index: -1;
    }
    .rightContent{
        .topInfo{
            h3{
                font-weight: 700;
                font-size: 40px;
                margin-bottom: 10px;
            }
            p{
                font-size: 15px;
                margin-bottom: 7px;
            }
        }
        .bottomInfo{
            margin-top: 30px;
            .singleItem{
                display: flex;
                column-gap: 20px;
                margin-bottom: 30px;
                .box-icon{
                    min-width: 60px;
                    height: 60px;
                    line-height: 60px;
                    text-align: center;
                    border-radius: 14px;
                    background-color: #6b61fc;
                    box-shadow: 0px 10px 30px rgba(62, 137, 249, 0.35);
                    img{
                        height: 30px;
                    }
                    &.style2{
                        background-color: #F3BA0E;
                        box-shadow: 0px 10px 30px rgba(243, 186, 14, 0.35);
                    }
                }
                .content{
                    h4{
                        font-weight: 600;
                        font-size: 18px;
                    }
                    p{
                        width: 70%;
                        font-size: 15px;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}
#customerJourney{
    padding: 70px 0px 60px 0px;
    .topInfoTitle{
        text-align: center;
        h3{
            font-weight: 700;
            color: #F3BA0E;
        }
        p{
            margin-top: 5px;
        }
    }
    .bottomWrapper{
        width: 80%;
        margin: 0 auto;
        margin-top: 40px;
        position: relative;
        .singleItem{
            padding: 40px 40px;
            height: 100%;
            border: 1px solid #e5e5e560;
            display: flex;
            column-gap: 10px;
            .icon{
                min-width: 60px;
                min-height: 60px;
                img{
                    height: 45px;
                }
            }
            .content{
                h3{
                    font-weight: 600;
                    font-size: 17px;
                }
                p{
                    font-size: 15px;
                    margin-top: 5px;
                }
            }
            &.item2,&.item3{
                background-color: #F5F5F5;
                border: none !important;
                box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
            }
            // &.item1,&.item3{
            //     border-right: none;
            // }
            // &.item1{
            //     border-radius: 15px 0px 0px 0px;
            // }
            // &.item2{
            //     border-bottom: none;
            //     border-radius: 0px 15px 0px 0px;
            // }
            // &.item3{
            //     border-top: none;
            //     border-radius: 0px 0px 0px 15px;
            // }
            // &.item4{
            //     border-radius: 0px 0px 15px 0px;
            // }
        }
    }
}
#utilizeTecnlogy{
    padding:100px 0px 0px 0px;
    .leftContent{
        .topInfo{
            h3{
                font-weight: 700;
                font-size: 40px;
                line-height: 47px;
                span{
                    color: $mainColor;
                }
            }
            p{
                margin-top: 10px;
            }
        }
        .bottomContent{
            margin-top: 40px;
            .singleItem{
                display: flex;
                column-gap: 10px;
                margin-bottom: 25px;
                .icon{
                    min-width: 60px;
                    img{
                        height: 50px;
                    }
                }
                .content{
                    h4{
                        color: $mainColor;
                        font-weight: 700;
                        font-size: 18px;
                    }
                    p{
                        width: 70%;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
    .rightContent{
        img{
            width: 80%;
        }
        h5{
            font-weight: 600;
            font-size: 16px;
            line-height: 25px;
            border: 1px solid #e5e5e5;
            span{
                color: $mainColor;
            }
        }
    }
    .bottomInfoBox{
        margin-top: 40px;
        .content{
            box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
            border-radius: 10px;
            padding: 20px;
            position: relative;
            padding-left: 90px;
            overflow: hidden;
            border: 2px solid $titleColor;
            .awardIcon{
                position: absolute;
                height: 100%;
                left: 0;
                top: -5px;
            }
            h5{
                font-weight: 500;
                line-height: 24px;
                font-size: 16px;
                span{
                    color: $mainColor;
                }
            }
        }
    }
}
#realtimeReporting{
    padding: 100px 0px 60px 0px;
    background-color: #f8f5fe4b;
    .leftContent{
        .topInfo{
            h3{
                font-weight: 700;
                font-size: 50px;
                span{
                    color: $mainColor;
                }
            }
            p{
                width: 85%;
                margin-top: 5px;
            }
        }
        .bottomImage{
            margin-top: 40px;
        }
    }
    .singleImage{
        border: 2px solid $mainColor;
        margin-bottom: 30px;
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.048) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        img{
            width: 100%;
        }
    }
}
#caseStudy{
    padding: 100px 0px;
    background-color: #4e2fda;
    position: relative;
    z-index: 1;
    .sectionBg{
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
    }
    .sectionBg2{
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
    }
    .mainContent{
        text-align: center;
        h3{
            color: #fff;
            font-weight: 700;
            font-size: 40px;
            line-height: 56px;
        }
        p{
            width: 50%;
            margin: 0 auto;
            margin-top: 15px;
            color: #fff;
        }
        .btn{
            margin-top: 30px;
            background-color: #fff;
            color: $mainColor;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
        }
    }
}
#footer{
    padding: 60px 0px 20px 0px;
    .footerLogo{
        a{
            img{
                height: 40px;
            }
        }
        p{
            margin-top: 10px;
            font-size: 14px;
            font-weight: 500;
        }
    }
    .footerComon{
        h4{
            font-weight: 600;
            font-size: 16px;
            color: #3a3c3fb9;
            margin-bottom: 10px;
        }
        p{
            width: 80%;
            font-size: 14px;
            font-weight: 500;
        }
        a{
            width: 80%;
            font-size: 14px;
            font-weight: 500;
            display: block;
            margin-bottom: 4px;
            &:hover{
                text-decoration: underline;
                color: $mainColor;
            }
        }
    }
    .followSocial{
        h4{
            font-weight: 600;
            font-size: 16px;
            color: #8e9fb8;
            margin-bottom: 10px;
        }
        ul{
            li{
                display: inline-block;
                margin-right: 4px;
                a{
                    height: 35px;
                    width: 35px;
                    border: 1px solid #e5e5e5;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 30px;
                    color: #b3acac;
                    font-size: 14px;
                    &:hover{
                        background-color: $mainColor;
                        border-color: $mainColor;
                        color: #fff;
                    }
                }
            }
        }
    }
    .coptrightContent{
        margin-top: 40px;
        border-top: 1px solid #e5e5e5;
        padding: 20px 0px 10px 0px;
        .leftInfo{
            p{
                font-weight: 500;
                font-size: 15px;
            }
        }
        .rightLink{
            ul{
                text-align: right;
                li{
                    display: inline-block;
                    margin-left: 10px;
                    a{
                        font-weight: 600;
                        font-size: 14px;
                        color: #746b6b;
                        &:hover{
                            color: $mainColor;
                        }
                    }
                }
            }
        }
    }
}